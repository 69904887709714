<script>
import Layout from '../../layout/Layout'
import DefaultMixin from '../mixin'
import Menu from "@/components/processos/components/menu/menu"
// import {USpinnerUloc} from 'uloc-vue'

export default {
  name: 'ProcessosPage',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    Layout,
    // USpinnerUloc
  },
  data () {
    return {
      active: this.$route.name.split('.').shift()
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  mounted () {
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    setActive (v) {
      this.active = v
    }
  },
  watch: {
    '$route' (r) {
      this.active = r.name.split('.').shift()
    }
  },
  meta: {
    title: 'Processos',
    name: 'Processos'
  }
}
</script>

<template>
  <layout menu-name="PRO" class="remocao" no-title :menu="menu"
          :menu-active="active" hfull>
          <router-view />
  </layout>
</template>

<style src="../assets/default.styl" lang="stylus"></style>
