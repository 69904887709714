export default [
  {
    id: 'processos',
    title: 'Processos',
    icon: 'archive',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    url: '/#/processo/processos'
  },
  {
    id: 'advogados',
    title: 'Advogados',
    icon: 'user-tie',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    url: '/#/pessoas?tags=Advogado'
  },
  {
    id: 'escrivaes',
    title: 'Escrivães',
    icon: 'users',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    url: '/#/pessoas?tags=Escrivao'
  },
  {
    id: 'juizes',
    title: 'Juízes',
    icon: 'user',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    url: '/#/pessoas?tags=Juiz'
  },
  /*{
    id: 'juizos',
    title: 'Juízo',
    icon: 'user',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    url: '/#/processo/juizos'
  },*/
  {
    id: 'tribunais',
    title: 'Tribunais',
    icon: 'building',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    url: '/#/processo/tribunais'
  },
  {
    id: 'comarcas',
    title: 'Comarcas',
    icon: 'building',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    url: '/#/processo/comarcas'
  },
  {
    id: 'varas',
    title: 'Varas',
    icon: 'building',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    url: '/#/processo/varas'
  }
]
